/**
 * @type {String}
 */
export const fullWidthMediaFragment = `
  ... on FullWidthMedia {
    sys {
      id
    }
    backgroundColor
    topPadding
    bottomPadding
    heading
    subheading
    headingAlignment
    media {
      url
      width
      height
      contentType
    }
    videoWidth
    videoHeight
    autoplay
    showControls
    loop
    disclosures { 
      json
    }
  }
`;
