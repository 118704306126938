import { ctaFragment } from 'humanity/components/cta/cta.fragment';

export const ctaBannerFragment = `
  ... on CtaBanner {
    sys {
      id
    }
    backgroundColor
    alignment
    topPadding
    bottomPadding
    heading
    subheading
    cta {
      ${ctaFragment}
    }
    valueProps
    disclosures {
      json
    }
  }
`;
