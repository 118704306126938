import { imageFragment } from 'legacy/graphql/fragments/components/imageFragment';

/**
 * @type {string}
 */
export const compactBannerFragment = `
  ... on CompactBanner {
    sys {
      id
    }
    heading
    bgColor
    imagesCollection(limit: 4) {
      items {
        sys {
          id
        }
        ${imageFragment}
      }
    }
    disclosures {
      json
    }
  }
`;
