import { SVGAttributes } from 'react';

import { theme } from 'utils/theme.v2';

interface GuideIcon extends SVGAttributes<SVGElement> {
  size: number;
  color: string;
}

// TODO: Get hi-react-dripicons working and replace this
const Guide = ({ size = 24, color = theme.colors.blue100, ...props }: GuideIcon) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.11173 4.5705V18.2333C2.11173 18.5475 2.36627 18.8038 2.67836 18.8038H9.44902C10.4694 18.8038 11.347 20.2011 11.6027 20.7437C11.6027 20.7727 11.6591 20.8295 11.6879 20.8574C11.7167 20.8863 11.7443 20.9142 11.8008 20.9432C11.8295 20.9432 11.886 20.9721 11.9136 21H11.9424H12.0553H12.1682C12.2534 20.971 12.3386 20.9142 12.4227 20.8574C12.4515 20.8284 12.4792 20.7716 12.5079 20.7437C12.7625 20.2022 13.6412 18.8038 14.6616 18.8038H21.4323C21.7444 18.8038 21.9989 18.5475 21.9989 18.2333L22 4.5705C22 4.25627 21.7455 4 21.4334 4H14.6627C13.5582 4 12.5666 4.5705 12 5.42624C11.4046 4.5705 10.4418 4 9.33729 4H2.65336C2.34126 4 2.11173 4.25628 2.11173 4.5705ZM14.7756 5.14099H20.9797V17.6628H14.7756C13.9257 17.6628 13.1886 18.1475 12.6795 18.6902V7.25114C12.6795 6.08117 13.6147 5.14099 14.7756 5.14099ZM3.24499 5.14099H9.44902C10.6111 5.14099 11.5451 6.08254 11.5451 7.25137V18.6904C11.0073 18.1489 10.299 17.663 9.44902 17.663H3.24499V5.14099Z"
      fill="#022A4D"
    />
    <path d="M4.5 8H10" stroke="#022A4D" strokeLinecap="round" />
    <path d="M4.5 11H10" stroke="#022A4D" strokeLinecap="round" />
    <path d="M4.5 14H10" stroke="#022A4D" strokeLinecap="round" />
    <path d="M14 8H19.5" stroke="#022A4D" strokeLinecap="round" />
    <path d="M14 11H19.5" stroke="#022A4D" strokeLinecap="round" />
  </svg>
);

export default Guide;
