import { ctaFragment } from 'humanity/components/cta/cta.fragment';
import { numberedStepFragment } from 'humanity/components/numberedStep/numberedStep.fragment';

export const numberedStepListFragment = `
  ... on NumberedStepList {
    sys {
      id
    }
    heading
    backgroundColor
    topPadding
    bottomPadding
    stepsCollection(limit: 3) {
      items {
        ${numberedStepFragment}
      }
    }
    cta {
      ${ctaFragment}
    }
  }
`;
