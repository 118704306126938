/**
 * @type {string}
 */
export const storefrontPageFragment = (sectionFragments) => `
  ... on StorefrontPage {
    sys {
      id
    }
    sectionsCollection(limit: 15) {
      items {
        __typename
        ${sectionFragments}
      }
    }
    notesCollection(limit: 10) {
      items {
        sys {
          id
        }
        content {
          json
        }
      }
    }
    disclosuresCollection(limit: 10) {
      items {
        sys {
          id
        }
        content {
          json
        }
      }
    }
  }
`;
