import { formFragment } from 'humanity/components/contentfulForm/form.fragment';
import { ctaFragment } from 'humanity/components/cta/cta.fragment';
import { fastTrackMastheadFormFragment } from 'humanity/components/fastTrackMastheadForm';

/* eslint-disable import/prefer-default-export */
export const basicMastheadFragment = `
  ... on BasicMasthead {
    sys {
      id
    }
    variant
    image {
      url
    }
    imageAlignment
    contentPosition
    eyebrow
    heading
    paragraph {
      json
    }
    form {
      __typename
      ${formFragment()}
      ${fastTrackMastheadFormFragment}
    }
    cta {
      ${ctaFragment}
    }
    disclosures {
      json
    }
  }
`;
