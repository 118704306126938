import { ctaFragment } from 'humanity/components/cta/cta.fragment';
import { valuePropWithImageFragment } from 'humanity/components/valuePropWithImage/valuePropWithImage.fragment';

export const valuePropListFragment = `
  ... on ValuePropList {
    sys {
      id
    }
    backgroundColor
    topPadding
    bottomPadding
    heading
    headingAlignment
    subheading
    valuePropsCollection(limit: 6) {
      items {
        ${valuePropWithImageFragment}
      }
    }
    cta {
      ${ctaFragment}
    }
    disclosures {
      json
    }
  }
`;
