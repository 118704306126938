import { layoutFragment } from 'legacy/graphql/fragments/components/layoutFragment';
import { seoMetadataFragment } from 'legacy/graphql/fragments/primitives/seoMetadataFragment';
import { variationWrapperFragment } from 'legacy/graphql/fragments/primitives/variationWrapperFragment';

export const pageContainerBySlug = (slug, pageTypeFragment, preview = false) => `
  query {
    pageContainerCollection(
      where: { slug: "${slug}" },
      limit: 1,
      preview: ${preview}
    ) {
      items {
        sys {
          id
        }
        internalTitle
        slug
        seoMetadata {
          ${seoMetadataFragment}
        }
        layout {
          ${layoutFragment}
        }
        page {
          __typename
          ${pageTypeFragment}
        }
      }
    }
  }
`;

/* eslint-disable import/prefer-default-export */
export const pageSettingsBySlug = (slug, preview = false) => `
  query {
    pageContainerCollection(
      where: { slug: "${slug}" },
      limit: 1,
      preview: ${preview}
    ) {
      items {
        sys {
          id
        }
        internalTitle
        slug
        seoMetadata {
          ${seoMetadataFragment}
        }
        layout {
          ${layoutFragment}
        }
      }
    }
  }
`;

export const pageContentBySlug = (slug, pageTypeFragment, preview = false) => `
  query {
    pageContainerCollection(
      where: { slug: "${slug}" },
      limit: 1,
      preview: ${preview}
    ) {
      items {
        sys {
          id
        }
        page {
          __typename
          ${pageTypeFragment}
          ${variationWrapperFragment(pageTypeFragment)}
        }
      }
    }
  }
`;
