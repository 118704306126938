export const ctaBoxStandard = {
  internalTitle: '[Billy] Testing',
  backgroundColor: 'backgroundLightGray',
  innerBackgroundColor: 'brightBlue10',
  header: 'Industry-low asset fees of 0.15% annually.  No transaction fees.',
  topPadding: 64,
  bottomPadding: 64,
  textField: {
    json: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Lorem ipsum dolor sit amet consectetur. Tortor ac porttitor tellus luctus sed quis. Nibh scelerisque in tristique ipsum felis nibh proin facilisi accumsan. Quam feugiat sit augue tincidunt. ',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
  },
  disclosure: {
    json: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Lorem ipsum dolor sit amet consectetur. Tortor ac porttitor tellus luctus sed quis. Nibh scelerisque in tristique ipsum felis nibh proin facilisi accumsan. Quam feugiat sit augue tincidunt. ',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
  },
  cta: {
    type: 'button',
    variant: 'primary',
    label: 'Get Started',
    url: '/testing/',
    openInNewTab: false,
    sys: {
      id: '4T5fU7kHJ2BxMPwFarmFaz',
    },
  },
  image: {
    altText: '$0 rollover fee, $0 loan fee, $0 distribution fee',
    width: 233,
    height: 212,
    sys: {
      id: '3PW4iW0B6aBkiEbIMvEnt3',
    },
    image: {
      title: '$0 fee buttons',
      url: 'https://images.ctfassets.net/tj9jxg7kaxby/7pMjPeDcUjQxAAhJLtWYA/dc449d75521642b140798b5db086ad31/Frame_3492.png',
      description: '',
    },
  },
};
