export const lcResourcePreviewStandard = {
  sys: {
    id: 'zmevXlygjAzBTxm03hyxK',
  },
  title: 'Financial advisor’s quick guide to SECURE Act 2.0',
  lastModified: '2023-05-08T16:14:52.471Z',
  categories: ['employers'],
  subcategories: ['federal-legislation', 'secure-act'],
  resourceType: 'Articles',
  resource: {
    __typename: 'LearningCenterArticle',
    slug: 'secure-act-guide-financial-advisors',
    image: {
      url: 'https://images.ctfassets.net/tj9jxg7kaxby/2BytcoFw5hfFrAkuIGg6gT/1f12df7fb313f1ecb07498aa7d9db9b0/financial_advisor_image.jpg',
    },
  },
};
