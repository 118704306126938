/* eslint-disable import/prefer-default-export */
import { formFragment } from '../components/formFragment';

/**
 * @type {string}
 */
export const leadCaptureFragment = `
  ... on LeadCapture {
    sys {
      id
    }
    heading
    paragraph
    form {
      ${formFragment}
    }
    disclaimer
  }
`;
