import { ctaFragment } from 'humanity/components/cta/cta.fragment';

export const pricingPlanFragment = `
  ... on PricingPlan {
    sys {
      id
    }
    title
    badge
    baseFee
    discountedBaseFee
    employeeFee
    discountedEmployeeFee
  }
`;

export const pricingCardFragment = `
  ... on PricingCardV2 {
    sys {
      id
    }
    plan {
      ${pricingPlanFragment}
    }
    badgeColor
    extraBadgeText
    baseFeeText
    employeeFeeText
    content {
      json
    }
    cta {
      ${ctaFragment}
    }
    featuresButtonText
  }
`;
