/* eslint-disable import/prefer-default-export */
import { statisticFragment } from 'legacy/graphql/fragments/components/statisticFragment';

/**
 * @type {string}
 */
export const statisticsSectionFragment = `
  ... on StatisticsSection {
    sys {
      id
    }
    statisticsCollection(limit: 3) {
      items {
        ${statisticFragment}
      }
    }
  }
`;
