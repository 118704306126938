/**
 * @type {String}
 */
export const embeddedMediaFragment = `
  ... on EmbeddedMedia {
    sys {
      id
    }
    backgroundColor
    topPadding
    bottomPadding
    heading
    subheading
    headingAlignment
    title
    url
    mediaWidth
    mediaHeight
    disclosures {
      json
    }
  }
`;
