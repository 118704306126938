/**
 * @type {String}
 */
export const faqFragment = `
  ... on Faq {
    sys {
      id
    }
    title
    content {
      json
    }
  }
`;
