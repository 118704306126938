import styled, { useTheme } from 'styled-components';
import css from '@styled-system/css';

import { lcResourcePreviewProps } from 'humanity/components/lcResourcePreview/lcResourcePreview.types.js';
import Box from 'humanity/primitives/box';
import Link from 'humanity/primitives/link';
import getResourceUrl from 'utils/getResourceUrl';
import { track } from 'utils/analytics';
import Image from 'humanity/primitives/image';
import Flex from 'humanity/primitives/flex';
import { Badge } from 'humanity/components/badge';
import { getLcIcon } from 'utils/getLcIcon';
import Heading from 'humanity/primitives/heading';
import { kebabToTitle } from 'utils/convertCase';
import Tooltip from 'humanity/components/tooltip';

const ImageLink = styled(Link)(
  css({
    '&:focus': {
      boxShadow: 'none',
      '& > div': {
        boxShadow: 'buttonFocus',
      },
    },
  })
);

const ImageWrapper = styled(Box)(
  css({
    position: 'relative',
    borderRadius: 'sm',
    pt: 'calc(200/277 * 100%)',
    overflow: 'hidden',
    lineHeight: 0,
  })
);

const HeadingLink = styled(Link)(
  css({
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    color: 'blue100',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
      outlineColor: 'blue20',
      outlineStyle: 'solid',
      outlineWidth: '2px',
    },
  })
);

const handleImageClick = (url, src) =>
  track('clicked_image_link', {
    target_url: url,
    img_src: src,
  });

const tooltipCardProps = {
  borderRadius: 'lg',
  bg: 'backgroundLightGray2',
  p: 2,
  fontSize: 0,
  fontWeight: 'bold',
  color: 'blue100',
};

const LcResourcePreview = ({ resource }) => {
  const theme = useTheme();

  const resourceUrl = getResourceUrl(resource.resource);
  const CategoryIcon = getLcIcon(resource.categories?.[0]);
  const ResourceTypeIcon = getLcIcon(resource.resourceType?.toLowerCase());

  return (
    <Flex as="article" flexDirection="column" gap="12px">
      <ImageLink
        href={resourceUrl}
        title={resource.title}
        onClick={() => handleImageClick(resourceUrl, resource.resource?.image?.url)}
        prefetch={false}
      >
        <ImageWrapper>
          <Image
            src={resource.resource?.image?.url}
            style={{ objectFit: 'cover' }}
            imgixParams={{ fit: 'crop', ar: '277:200' }}
            sizes={`(min-width: ${theme.breakpoints.md}) calc(50vw - 3rem), (min-width: ${theme.breakpoints.lg}) calc(33vw - 2rem), calc(100vw - 2rem)`}
            fill
            alt=""
          />
        </ImageWrapper>
      </ImageLink>
      <Flex gap={1} alignItems="center">
        {resource.subcategories?.[0] && (
          <Badge
            bg="teal20"
            label={kebabToTitle(resource.subcategories?.[0]).toUpperCase()}
          />
        )}
        {resource.categories?.[0] && (
          <Tooltip
            title={kebabToTitle(resource.categories?.[0])}
            placement="top"
            cardProps={tooltipCardProps}
          >
            <CategoryIcon size={16} color={theme.colors.blue100} />
          </Tooltip>
        )}
        {ResourceTypeIcon && (
          <Tooltip
            title={resource.resourceType}
            placement="top"
            cardProps={tooltipCardProps}
          >
            <ResourceTypeIcon size={16} color={theme.colors.blue100} />
          </Tooltip>
        )}
      </Flex>
      <Heading as="h2" fontSize="body" lineHeight="body">
        <HeadingLink href={resourceUrl} prefetch={false}>
          {resource.title}
        </HeadingLink>
      </Heading>
    </Flex>
  );
};

LcResourcePreview.propTypes = {
  ...lcResourcePreviewProps,
};

export default LcResourcePreview;
