/* eslint-disable import/prefer-default-export */
import { formFragment } from 'legacy/graphql/fragments/components/formFragment';

/**
 *
 * @param {string} id The Contentful entry ID
 * @param {boolean} [preview=false]
 * @returns
 */
export const formById = (id, preview = false) => `
  query {
    form(id: "${id}", preview: ${preview}) {
      ${formFragment}
    }
  }
`;
