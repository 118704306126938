export const sentenceCaseToCamelCase = (text) => {
  if (!text) return null;

  return text
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
};

const spaceAndUpper = (text) => text.replace(/-/, ' ').toUpperCase();

export const kebabToTitle = (text) => {
  if (!text) return null;

  const spacedAndUppered = text.replace(/-\w/g, spaceAndUpper);

  return spacedAndUppered.charAt(0).toUpperCase() + spacedAndUppered.slice(1);
};

// shamelessly stolen from StackOverflow 😁
const clearAndUpper = (text) => text.replace(/-/, '').toUpperCase();

export const kebabToCamel = (text) => {
  if (!text) return null;
  return text.replace(/-\w/g, clearAndUpper);
};

export const kebabToPascal = (text) => {
  if (!text) return null;
  return text.replace(/(^\w|-\w)/g, clearAndUpper);
};

export default {
  sentenceCaseToCamelCase,
  kebabToCamel,
  kebabToPascal,
};
