/* eslint-disable import/prefer-default-export */
/**
 * @type {string}
 */
export const valuePropWithImageFragment = `
  ... on ValuePropWithImage {
    sys {
      id
    }
    image {
      url
    }
    heading
    content {
      json
    }
  }
`;
