import { ctaFragment } from 'humanity/components/cta/cta.fragment';
import { payrollIntegrationCardFragment } from 'humanity/components/payrollIntegrationCard/payrollIntegrationCard.fragment';

/**
 * @type {String}
 */
export const payrollIntegrationsFragment = `
  ... on PayrollIntegrations {
    sys {
      id
    }
    backgroundColor
    topPadding
    bottomPadding
    heading
    payrollSubheading: subheading {
      json
    }
    integrationLogosCollection(limit: 54) {
      items {
        ${payrollIntegrationCardFragment}
      }
    }
    completeIntegrationsList {
      sys {
        id
      }
    }
    cta {
      ${ctaFragment}
    }
    ignoreCtaUrl
    modalCta {
      ${ctaFragment}
    }
  }
`;
