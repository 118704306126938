import { ctaFragment } from 'humanity/components/cta/cta.fragment';

/**
 * @type {String}
 */
export const valuePropFragment = `
  ... on ValuePropV2 {
    sys {
      id
    }
    heading
    content {
      json
    }
    cta {
      ${ctaFragment}
    }
  }
`;
