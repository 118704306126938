import { ctaFragment } from 'humanity/components/cta/cta.fragment';

/**
 * @type {String}
 */
export const inlineBannerCtaFragment = `
  ... on InlineBannerCta {
    sys {
      id
    }
    heading
    subheading
    variant
    cta {
      ${ctaFragment}
    }
  }
`;
