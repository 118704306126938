import {
  UserGroup,
  Store,
  UserId,
  Article,
  MediaPlay,
  Monitor,
  Scale,
  Briefcase,
} from 'react-dripicons';

import Guide from 'humanity/icons/guide';

const FILTER_ICONS = {
  employers: Store,
  employees: UserId,
  legislation: Briefcase,
  partners: UserGroup,
  articles: Article,
  calculators: Scale,
  guides: Guide,
  videos: MediaPlay,
  webinars: Monitor,
};

export const getLcIcon = (key) => {
  const icon = FILTER_ICONS[key];

  if (icon) return icon;

  return null;
};
