import { ctaFragment } from 'humanity/components/cta/cta.fragment';

/**
 * @type {String}
 */
export const simpleRichTextSectionFragment = `
  ... on SimpleRichTextSection {
    sys {
      id
    }
    topPadding
    bottomPadding
    textAlignment
    content {
      json
    }
    cta {
      ${ctaFragment}
    }
  }
`;
