/* istanbul ignore file */

/**
 * TODO: This file should be broken apart incrementally, since unfortunately,
 * when you import a named export, you get everything else along with it in the bundle.
 * Eventually, this should only contain the base fetchGraphQL and fetchViaApi functions.
 *
 * Don't put new stuff in here.
 */
import gqlmin from 'gqlmin';
import axios from 'axios';

import {
  learningCenterArticleBySlug,
  allLearningCenterArticleSlugs,
} from 'legacy/graphql/queries/learningCenterArticle';
import {
  learningCenterCalculatorBySlug,
  allLearningCenterCalculatorSlugs,
} from 'legacy/graphql/queries/learningCenterCalculator';
import {
  learningCenterGuideBySlug,
  allLearningCenterGuideSlugs,
} from 'legacy/graphql/queries/learningCenterGuide';
import { pageBySlug } from 'legacy/graphql/queries/page';
import { formById } from 'legacy/graphql/queries/form';
import { signupVariantById } from 'legacy/graphql/queries/signupVariant';
import careersPage from 'legacy/graphql/queries/careersPage';
import { pressroomArticleByCategory } from 'legacy/graphql/queries/pressroomArticle';
import {
  companyNewsArticleBySlug,
  allCompanyNewsArticleSlugs,
  companyNewsArticlesByPage,
} from 'legacy/graphql/queries/companyNewsArticle';
import {
  customerStoryBySlug,
  allCustomerStoriesBySlugs,
  customerStoriesByPage,
} from 'legacy/graphql/queries/customerStory';
import {
  limitLcResourcesByKeyword,
  allLcResourcesCountByKeyword,
} from 'legacy/graphql/queries/search';
import { keyValueByKey } from 'legacy/graphql/queries/keyValue';
import {
  pageContainerBySlug,
  pageContentBySlug,
  pageSettingsBySlug,
} from 'legacy/graphql/queries/pageContainer';
import {
  allIndustriesPageSlugs,
  allLandingPageSlugs,
  allPartnersPageSlugs,
  allPayrollsPageSlugs,
  allSolutionsPageSlugs,
  allStateComparisonsPageSlugs,
  getPageSlugsQuery,
} from 'humanity/pages/storefrontPage/storefrontPage.query';

/**
 *
 * @typedef {object} PaginatedResponse
 * @property {number} limit   The "page" size
 * @property {number} total   The total number of requested resources
 * @property {number} skip    The current offset
 * @property {object[]} items The requested resources
 */

// This function is used when we need to fetch via XHR, so we don't expose our access
// token on the client
export const fetchViaApi = async (query, preview = false) => {
  const res = await axios.post(
    '/api/contentful/graphql/',
    { query, preview },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return res.data;
};

export const fetchGraphQL = async (query, preview = false) => {
  const minified = gqlmin(query);
  const res = await axios.post(
    `${process.env.NEXT_PUBLIC_CONTENTFUL_GRAPHQL_BASE_URL}/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/environments/${process.env.NEXT_PUBLIC_CONTENTFUL_ENV}`,
    { query: minified },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          preview
            ? process.env.CONTENTFUL_PREVIEW_TOKEN
            : process.env.CONTENTFUL_ACCESS_TOKEN
        }`,
      },
      timeout: 10000,
    }
  );

  return res.data;
};

export const getLearningCenterArticleBySlug = async (slug, preview = false) => {
  const query = learningCenterArticleBySlug(slug, preview);

  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.learningCenterArticleCollection?.items?.[0];
};

export const getAllLearningCenterArticleSlugs = async () => {
  const entry = await fetchGraphQL(allLearningCenterArticleSlugs);

  return entry?.data?.learningCenterArticleCollection?.items;
};

export const getLearningCenterCalculatorBySlug = async (slug, preview = false) => {
  const query = learningCenterCalculatorBySlug(slug, preview);

  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.learningCenterCalculatorCollection?.items?.[0];
};

export const getAllLearningCenterCalculatorSlugs = async () => {
  const entry = await fetchGraphQL(allLearningCenterCalculatorSlugs);

  return entry?.data?.learningCenterCalculatorCollection?.items;
};

export const getLearningCenterGuideBySlug = async (slug, preview = false) => {
  const query = learningCenterGuideBySlug(slug, preview);

  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.learningCenterGuideCollection?.items?.[0];
};

export const getAllLearningCenterGuideSlugs = async () => {
  const entry = await fetchGraphQL(allLearningCenterGuideSlugs);

  return entry?.data?.learningCenterGuideCollection?.items;
};

export const getPageBySlug = async (slug, sectionsFields, preview = false) => {
  const query = pageBySlug(slug, sectionsFields, preview);

  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.pageCollection?.items?.[0];
};

export const getFormById = async (id, preview = false) => {
  const query = formById(id, preview);

  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.form;
};

export const getSignupSectionsByVariantId = async (id, preview = false) => {
  const query = signupVariantById(id);

  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.variantCollection?.items?.[0]?.sectionsCollection?.items;
};

export const getCareersPage = async (preview = false) => {
  const query = careersPage(preview);

  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.careersPageCollection?.items?.[0];
};

export const getPressroomArticlesByCategory = async (
  category,
  limit,
  skip,
  preview = false
) => {
  const query = pressroomArticleByCategory(category, limit, skip);

  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.pressroomArticleCollection;
};

export const getCompanyNewsArticlebySlug = async (slug, preview = false) => {
  const query = companyNewsArticleBySlug(slug, preview);

  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.blogArticleCollection?.items?.[0];
};

export const getAllCompanyNewsArticleSlugs = async (preview = false) => {
  const entry = await fetchGraphQL(allCompanyNewsArticleSlugs, preview);

  return entry?.data?.blogArticleCollection?.items;
};

/**
 *
 * @param {number} limit
 * @param {number} skip
 * @param {boolean} preview
 * @returns {PaginatedResponse}
 */
export const getCompanyNewsArticlesByPage = async (limit, skip, preview = false) => {
  const query = companyNewsArticlesByPage(limit, skip, preview);
  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.blogArticleCollection;
};

/**
 * @param {string} slug The customer story url slug to be retrieved.
 * @param {boolean} preview
 */
export const getCustomerStorybySlug = async (slug, preview = false) => {
  const query = customerStoryBySlug(slug, preview);

  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.customerStoryCollection?.items?.[0];
};

/**
 *
 * @param {boolean} preview
 * @returns
 */
export const getAllCustomerStoryiesBySlugs = async (preview = false) => {
  const entry = await fetchGraphQL(allCustomerStoriesBySlugs, preview);

  return entry?.data?.customerStoryCollection?.items;
};

/**
 *
 * @param {number} limit
 * @param {number} skip
 * @param {boolean} preview
 * @returns {PaginatedResponse}
 */
export const getCustomerStoriesByPage = async (limit, skip, preview = false) => {
  const query = customerStoriesByPage(limit, skip, preview);
  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.customerStoryCollection;
};

/**
 *
 * @param {string} keyword  The string keyword to search LC resources for.
 * @param {number} limit                  The max number of items to get.
 * @param {number} skipArticles           How many items to skip over.
 * @param {number} skipGuides             How many items to skip over.
 * @param {number} skipCalculators        How many items to skip over.
 * @param {string} category
 * @param {boolean} preview
 * @returns
 */
export const getLcResourcesByKeyword = async (
  keyword = '',
  limit = 6,
  limitArticlesTitles = 6,
  limitArticlesBodies = -1,
  skipArticlesTitles = 0,
  skipArticlesBodies = 0,
  skipGuides = 0,
  skipCalculators = 0,
  category = '',
  preview = false
) => {
  try {
    const query = limitLcResourcesByKeyword(
      keyword,
      limit,
      limitArticlesTitles,
      limitArticlesBodies,
      skipArticlesTitles,
      skipArticlesBodies,
      skipGuides,
      skipCalculators,
      category
    );
    const entry = await fetchGraphQL(query, preview);

    const results = {
      lcArticles: {
        total: entry.data.lcArticlesTitles.total + entry.data.lcArticlesBodies.total,
        items: [
          ...entry.data.lcArticlesTitles.items,
          ...entry.data.lcArticlesBodies.items,
        ],
      },
      lcGuides: entry.data.lcGuides,
      lcCalculators: entry.data.lcCalculators,
    };

    return results;
  } catch (err) {
    return null;
  }
};

export const getAllLcCategories = async () => {
  const entry = await fetchGraphQL(`
  query {
    learningCenterCategoryCollection {
      items {
        title
        slug
      }
    }
  }
`);

  return entry?.data?.learningCenterCategoryCollection?.items;
};

export const getLcResourcesCountByKeyword = async (
  keyword = '',
  category = '',
  preview = false
) => {
  const query = allLcResourcesCountByKeyword(keyword, category);
  const entry = await fetchGraphQL(query, preview);

  return entry?.data;
};

export const getGlossaryListBySlug = async (slug, preview = false) => {
  const query = `query {
  pageContainerCollection (preview:${preview}, where: {slug_contains:"${slug}", slug_not:"learn/retirement-glossary"}) {
    items {
      sys {
        id
      }
      slug
      page {
        ... on PageContentRetirementGlossaryTerm {
          title
        }
      }
    }
  }
}
`;
  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.pageContainerCollection?.items;
};

export const getKeyValueByKey = async (key, preview = false) => {
  const query = keyValueByKey(key);
  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.keyValueCollection?.items?.[0];
};

export const getPageContainerBySlug = async (slug, pageTypeFragment, preview = false) => {
  const query = pageContainerBySlug(slug, pageTypeFragment, preview);
  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.pageContainerCollection?.items?.[0];
};

export const getPageSettingsBySlug = async (slug, preview = false) => {
  const query = pageSettingsBySlug(slug, preview);
  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.pageContainerCollection?.items?.[0];
};

export const getPageContentBySlug = async (slug, pageTypeFragment, preview = false) => {
  const query = pageContentBySlug(slug, pageTypeFragment, preview);
  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.pageContainerCollection?.items?.[0]?.page;
};

export const getAllIndustriesPageSlugs = async () => {
  const entry = await fetchGraphQL(allIndustriesPageSlugs);

  return entry?.data?.pageContainerCollection?.items;
};

export const getAllLandingPageSlugs = async (preview = false) => {
  const entry = await fetchGraphQL(allLandingPageSlugs, preview);

  return entry?.data?.pageContainerCollection?.items;
};

export const getAllSolutionsPageSlugs = async (preview = false) => {
  const entry = await fetchGraphQL(allSolutionsPageSlugs, preview);

  return entry?.data?.pageContainerCollection?.items;
};

export const getAllPartnersPageSlugs = async (preview = false) => {
  const entry = await fetchGraphQL(allPartnersPageSlugs, preview);

  return entry?.data?.pageContainerCollection?.items;
};

export const getAllPayrollsPageSlugs = async (preview = false) => {
  const entry = await fetchGraphQL(allPayrollsPageSlugs, preview);

  return entry?.data?.pageContainerCollection?.items;
};

export const getAllStateComparisonsPageSlugs = async (preview = false) => {
  const entry = await fetchGraphQL(allStateComparisonsPageSlugs, preview);

  return entry?.data?.pageContainerCollection?.items;
};

export const getAllPageSlugsPaths = async (slug, preview = false) => {
  const query = getPageSlugsQuery(slug);
  const entry = await fetchGraphQL(query, preview);

  const pages = entry?.data?.pageContainerCollection?.items;
  const paths = pages.map((page) => ({
    params: { slug: page.slug.replace(`${slug}/`, '') },
  }));
  return paths;
};
