// eslint-disable-next-line import/prefer-default-export
export const seoMetadataFragment = `
  ... on SeoMetadata {
    sys {
      id
    }
    title
    description
    noIndex
    image {
      url
    }
    canonicalUrl
  }
`;
