/* eslint-disable import/prefer-default-export */
import { imageFragment } from 'legacy/graphql/fragments/components/imageFragment';

export const testimonialFragment = `
  ... on Testimonial {
    internalTitle
    testimonialQuote
    quoteAuthor
    authorImage {
      ${imageFragment}
    }
  }
`;
