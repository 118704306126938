/* eslint-disable import/prefer-default-export */

import { valuePropWithImageSectionFragment } from 'legacy/components/valuePropWithImageSection/valuePropWithImageSectionFragment';
import { layoutFragment } from 'legacy/graphql/fragments/components/layoutFragment';
import { mastheadFragment } from 'legacy/graphql/fragments/components/mastheadFragment';
import { disclosuresFragment } from 'legacy/graphql/fragments/primitives/disclosuresFragment';
import { seoMetadataFragment } from 'legacy/graphql/fragments/primitives/seoMetadataFragment';
import { leadCaptureFragment } from 'legacy/graphql/fragments/sections/leadCaptureFragment';
import { paragraphWithImageFragment } from 'legacy/graphql/fragments/sections/paragraphWithImageFragment';
import { statisticsSectionFragment } from 'legacy/graphql/fragments/sections/statisticsSectionFragment';
import { textBlockWithEmbedsFragment } from 'legacy/graphql/fragments/sections/textBlockFragment';
import { guideMastheadFragment } from 'humanity/sections/guideMasthead/guideMasthead.fragment';

/**
 *
 * @param {string} slug
 * @param {boolean} [preview=false]
 * @returns
 */
export const learningCenterGuideBySlug = (slug, preview = false) => `
  query {
    learningCenterGuideCollection(where: { slug: "${slug}"}, limit: 1, preview: ${preview}) {
      items {
        title
        slug
        seoMetadata {
          ${seoMetadataFragment}
        }
        categoriesCollection(limit: 1) {
          items {
            sys {
              id
            }
            title
            slug
          }
        }
        subcategoriesCollection(limit: 1) {
          items {
            sys {
              id
            }
            title
            slug
          }
        }
        layout {
          ${layoutFragment}
        }
        masthead {
          ${mastheadFragment}
        }
        downloadUrl
        leadCapture {
          ${leadCaptureFragment}
        }
        thankYouPageTopCopy {
          json
        }
        thankYouPageBottomCopy {
          json
        }
        sectionsCollection(limit: 8) {
          items {
            __typename
            ${guideMastheadFragment}
            ${paragraphWithImageFragment}
            ${textBlockWithEmbedsFragment}
            ${statisticsSectionFragment}
            ${valuePropWithImageSectionFragment}
          }
        }
        notes {
          ${disclosuresFragment}
        }
        disclosures {
          ${disclosuresFragment}
        }
      }
    }
  }
`;

/**
 * @type {string}
 */
export const allLearningCenterGuideSlugs = `
  query {
    learningCenterGuideCollection {
      items {
        slug
      }
    }
  }
`;
