import { inputFragment } from './inputFragment';

/**
 * @deprecated
 */
export const formFragment = `
  ... on Form {
    sys {
      id
    }
    title
    subtitle
    hubspotFormId
    hubspotSandboxFormId
    salesforceCampaignId
    formType
    disclosures {
      json
    }
    submitButtonText
    inputsCollection(limit: 15) {
      items {
        ${inputFragment}
      }
    }
  }
`;
