import { ctaFragment } from 'humanity/components/cta/cta.fragment';

export const largePricingCardFragment = `
  ... on LargePricingCard {
    sys {
      id
    }
    backgroundColor
    topPadding
    bottomPadding
    image {
      url
    }
    heading
    subheading
    planIntroText
    baseFee
    baseFeeText
    perEmployeeFee
    employeeFeeText
    cta {
      ${ctaFragment}
    }
    disclosures {
      json
    }
  }
`;
