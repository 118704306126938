// eslint-disable-next-line import/prefer-default-export
export const variationWrapperFragment = (variationFragments) => `
  ... on VariationWrapper {
    meta
    campaignId
    campaignTitle
    campaignKey
    variationsCollection(limit: 4) {
      items {
        __typename
        sys {
          id
        }
        ${variationFragments}
      }
    }
  }
`;
