/* eslint-disable import/prefer-default-export */
/**
 * @deprecated
 */
export const simpleRichTextFragment = `
  ... on ContentTypeRichText {
    sys {
      id
    }
    internalTitle
    content {
      json
    }
  }
`;
