import { testimonialCardFragment } from 'humanity/components/testimonialCard/testimonialCard.fragment';

export const testimonialsGridFragment = `
  ... on TestimonialsGrid {
    sys {
      id
    }
    backgroundColor
    topPadding
    bottomPadding
    heading
    subheading
    testimonialsCollection(limit: 3) {
      items {
        ${testimonialCardFragment}
      }
    }
    disclosures {
      json
    }
  }
`;
