/* eslint-disable import/prefer-default-export */
/**
 * @type {string}
 */
export const tableFragment = `
  ... on Table {
    table
    description
  }
`;
