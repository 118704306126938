import { testimonialFragment } from 'legacy/components/customerStory/testimonial/testimonialFragment';
import { customerStoryPreviewFragment } from 'legacy/graphql/fragments/components/customerStoryPreviewFragment';
import { imageFragment } from 'legacy/graphql/fragments/components/imageFragment';
import { layoutFragment } from 'legacy/graphql/fragments/components/layoutFragment';
import { mastheadFragment } from 'legacy/graphql/fragments/components/mastheadFragment';
import { disclosuresFragment } from 'legacy/graphql/fragments/primitives/disclosuresFragment';
import { seoMetadataFragment } from 'legacy/graphql/fragments/primitives/seoMetadataFragment';
import { socialSharingLinksFragment } from 'legacy/graphql/fragments/primitives/socialSharingLinksFragment';
import { tableFragment } from 'legacy/graphql/fragments/primitives/tableFragment';
import { ctaSectionFragment } from 'legacy/graphql/fragments/sections/ctaSectionFragment';
import { paragraphWithImageFragment } from 'legacy/graphql/fragments/sections/paragraphWithImageFragment';
import { resourceListFragment } from 'legacy/graphql/fragments/sections/resourceListFragment';
import { statisticsSectionFragment } from 'legacy/graphql/fragments/sections/statisticsSectionFragment';

/**
 *
 * @param {number} [limit=3]
 * @param {number} [skip=0]
 * @param {boolean} [preview=false]
 * @returns {string}
 */
export const customerStoryPageFragment = (limit = 3, skip = 0, preview = false) => `
  customerStoryCollection(
    limit: ${limit}
    skip: ${skip}
    order: publishDate_DESC
    preview: ${preview}
  ) {
    limit
    total
    skip
    items {
      sys {
        id
      }
      ${customerStoryPreviewFragment}
    }
  }
`;

/**
 *
 * @param {string} slug   The desired url slug to get
 * @param {boolean} [preview=false]     Whether we're in preview mode or not.
 * @returns
 */
export const customerStoryBySlug = (slug, preview = false) => `
  query {
    customerStoryCollection(
      where: {slug: "${slug}"}
      preview: ${preview}
      limit: 1
    ) {
      items {
        sys {
          id
        }
        disclosures {
          ${disclosuresFragment}
        }
        seoMetadata {
          ${seoMetadataFragment}
        }
        layout {
          ${layoutFragment}
        }
        publishDate
        slug
        title
        image {
          url
        }
        customerStoryType
        publishDate
        masthead {
          ${mastheadFragment}
        }
        industry
        companySize
        location
        payroll
        solution
        body {
          json
          links {
            assets {
              block {
                sys {
                  id
                }
                title
                url
              }
            }
            entries {
              hyperlink {
                __typename
                sys {
                  id
                }
              }
              inline {
                __typename
                sys {
                  id
                }
              }
              block {
                __typename
                sys {
                  id
                }
                ${imageFragment}
                ${paragraphWithImageFragment}
                ${ctaSectionFragment}
                ${tableFragment}
                ${testimonialFragment}
                ${statisticsSectionFragment}
              }
            }
          }
        }
        excerpt
        socialSharingOptions {
          ${socialSharingLinksFragment}
        }
        ctaSection {
          ${ctaSectionFragment}
        }
        discoverMoreStoriesSection {
          ${resourceListFragment}
        }
      }
    }
  }
`;

/**
 *
 * @param {number} limit Number of articles per page
 * @param {number} skip The desired offset
 * @param {boolean} preview Whether we are in "preview" mode
 * @returns {string} The GraphQL query
 */
export const customerStoriesByPage = (limit, skip, preview = false) => `
  query {
    ${customerStoryPageFragment(limit, skip, preview)}
  }
`;

export const allCustomerStoriesBySlugs = `
  query {
    customerStoryCollection {
      items {
        slug
      }
    }
  }
`;
