/* eslint-disable import/prefer-default-export */
/**
 * @deprecated
 */
export const ctaFragment = `
  ... on Cta {
    sys {
      id
    }
    internalTitle
    type
    variant
    label
    url
  }
`;
