import { layoutFragment } from 'legacy/graphql/fragments/components/layoutFragment';
import { mastheadFragment } from 'legacy/graphql/fragments/components/mastheadFragment';
import { disclosuresFragment } from 'legacy/graphql/fragments/primitives/disclosuresFragment';
import { seoMetadataFragment } from 'legacy/graphql/fragments/primitives/seoMetadataFragment';
import { ctaSectionFragment } from 'legacy/graphql/fragments/sections/ctaSectionFragment';
import { paragraphWithImageFragment } from 'legacy/graphql/fragments/sections/paragraphWithImageFragment';
import { textBlockSimpleFragment } from 'legacy/graphql/fragments/sections/textBlockFragment';

/**
 *
 * @param {string} slug
 * @param {boolean} [preview=false]
 * @returns
 */
export const learningCenterCalculatorBySlug = (slug, preview = false) => `
  query {
    learningCenterCalculatorCollection(
      where: { slug: "${slug}" },
      limit: 1,
      preview: ${preview}
    ) {
      items {
        sys {
          id
        }
        title
        calculator
        seoMetadata {
          ${seoMetadataFragment}
        }
        slug
        layout {
          ${layoutFragment}
        }
        masthead {
          ${mastheadFragment}
        }
        categoriesCollection(limit: 1) {
          items {
            sys {
              id
            }
            title
            slug
          }
        }
        subcategoriesCollection(limit: 1) {
          items {
            sys {
              id
            }
            title
            slug
          }
        }
        sectionsCollection(limit: 10) {
          items {
            __typename
            ${paragraphWithImageFragment}
            ${textBlockSimpleFragment}
            ${ctaSectionFragment}
          }
        }
        disclosures {
          ${disclosuresFragment}
        }
      }
    }
  }
`;

/**
 * @type {string}
 */
export const allLearningCenterCalculatorSlugs = `
  query {
    learningCenterCalculatorCollection {
      items {
        slug
      }
    }
  }
`;
