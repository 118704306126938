/**
 * @typedef SectionWithType
 * @property {string} __typename
 */

import { mapSectionTypesToFragments } from 'lib/contentful/mapSectionTypesToFragments';

/**
 * @typedef {object} PageContent
 * @property {string} __typename
 * @property {object} sectionsCollection
 * @property {SectionWithType[]} sectionsCollection.items
 */

/**
 * @typedef {object} PageContentVariations
 * @property {string} __typename
 * @property {object} variationsCollection
 * @property {PageContent[]} variationsCollection.items
 */

/**
 * @typedef {PageContent | PageContentVariations} Page
 */

/**
 * Returns a string of GraphQL fragments for each section needed in the query
 * @param {Page} page
 * @returns {string}
 */
export const getPageSectionFragments = (page) => {
  const pageType = page.__typename;

  let sections;

  if (pageType === 'VariationWrapper') {
    // iterate through each variation to get the sections and flatten to a single array
    sections = page.variationsCollection.items
      .map((item) => [...item.sectionsCollection.items])
      .flat();
  } else {
    sections = page.sectionsCollection.items;
  }

  // remove sections with duplicate __typename fields
  const dedupedSections = sections.filter(
    (section, index) =>
      sections.findIndex((item) => item.__typename === section.__typename) === index
  );

  return mapSectionTypesToFragments(dedupedSections);
};
