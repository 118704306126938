/**
 * @type {String}
 */
export const lcResourcePreviewFragment = `
  ... on LearningCenterResource {
    sys {
      id
    }
    title
    lastModified
    categories
    subcategories
    resourceType
    resource {
      __typename
      ... on LearningCenterArticle {
        slug
        image {
          url
        }
      }
      ... on LearningCenterCalculator {
        slug
        image {
          url
        }
      }
      ... on LearningCenterGuide {
        slug
        image {
          url
        }
      }
      ... on LearningCenterWebinar {
        slug
        image {
          url
        }
      }
    }
  }
`;
