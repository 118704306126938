import { ctaFragment } from 'humanity/components/cta/cta.fragment';
import { imageFragment } from 'legacy/graphql/fragments/components/imageFragment';

export const ctaBoxFragment = `
  ... on SectionCtaBox {    
    internalTitle
    image {
      ${imageFragment}
    }
    backgroundColor
    innerBackgroundColor
    header
    textField {
      json
    }
    disclosure {
      json
    }
    cta {
      ${ctaFragment}
    }
    topPadding
    bottomPadding
  }
`;
