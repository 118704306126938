import { imageFragment } from 'legacy/graphql/fragments/components/imageFragment';
import { formFragment } from 'legacy/graphql/fragments/components/formFragment';
/**
 * @deprecated
 */
// eslint-disable-next-line import/prefer-default-export
export const mastheadFragment = `
  ... on Masthead {
    sys {
      id
    }
    type
    backgroundColor
    eyebrow
    heading
    headingHighlight
    mobileImage {
      ${imageFragment}
    }
    tabletImage {
      ${imageFragment}
    }
    desktopImage {
      ${imageFragment}
    }
    form {
      ${formFragment}
    }
    cta {
      type
      label
      url
    }
    body {
      json
    }
    calculator
    contentPosition
  }
`;
