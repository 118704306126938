/* eslint-disable import/prefer-default-export */
export const iconFragment = `
  ... on Icon {
    sys {
      id
    }
    title
    icon
    url
  }
`;
