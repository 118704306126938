import { valuePropFragment } from 'humanity/components/valueProp/valueProp.fragment';

/**
 * @type {String}
 */
export const tabbedValuePropsFragment = `
  ... on TabbedValueProps {
    sys {
      id
    }
    image {
      url
      width
      height
    }
    valuePropsCollection(limit: 3) {
      items {
        ${valuePropFragment}
      }
    }
  }
`;
