import { getPageSettingsBySlug } from 'lib/contentful';
import { getVwoClient } from 'utils/vwo/serverClient';
import getVwoVariation from 'utils/vwo/getVwoVariation';
import { parseSetCookieString } from 'utils/parseSetCookieString';

/**
 * Parses the "pageSettings" response from Contentful for a potential VWO
 * variation wrapper and returns either the original response if no variation
 * wrapper exists or the modified response with the appropriate variation for
 * the user.
 *
 * @param {Object} entry The page settings object returned by Contentful
 * @param {Object} client An instance of the vwo-node-sdk client
 * @param {string} hinAnonymousId The user's anonymous ID assigned by us
 * @param {string} queryVariation An optional forced variation set by the "variation" query parameter
 * @returns {Object} An object containing the modified page settings object and
 *                   any experiment data for analytics.
 */
const parsePageSettings = (entry, client, hinAnonymousId, queryVariation) => {
  let experimentData = null;

  // right now we only allow experimentation on the headerNavigation field
  const {
    layout: { headerNavigation },
  } = entry;

  // If the entry is not a VariationWrapper, just return it unchanged
  if (headerNavigation?.__typename !== 'VariationWrapper')
    return {
      pageSettings: entry,
      experimentData,
    };

  // a little "hack" to get the experiment data from getVwoVariation
  const handleExperiment = (data) => {
    experimentData = data;
  };

  const nextHeaderNav = getVwoVariation(
    client,
    headerNavigation,
    hinAnonymousId,
    handleExperiment,
    queryVariation
  );

  const pageSettings = {
    ...entry,
    layout: {
      ...entry.layout,
      headerNavigation: nextHeaderNav,
    },
  };

  return { pageSettings, experimentData };
};

/**
 * Parses the "pageContent" response from Contentful for a potential VWO
 * variation wrapper and returns either the original response if no variation
 * wrapper exists or the modified response with the appropriate variation for
 * the user.
 *
 * @param {Object} entry The page content object returned by Contentful
 * @param {Object} client An instance of the vwo-node-sdk client
 * @param {string} hinAnonymousId The user's anonymous ID assigned by us
 * @param {string} queryVariation An optional forced variation set by the "variation" query parameter
 * @returns {Object} An object containing the modified page content object and
 *                   any experiment data for analytics.
 */
const parsePageContent = (entry, client, hinAnonymousId, queryVariation) => {
  let experimentData = null;

  // If the entry is not a VariationWrapper, just return it unchanged
  if (entry.__typename !== 'VariationWrapper')
    return { pageContent: entry, experimentData };

  // If the entry is wrapped with the special "None" campaign, it is not actually
  // an active experiment, so we just return the first and only item in the
  // variations array.
  if (entry.campaignKey === 'None')
    return { pageContent: entry.variationsCollection.items[0], experimentData };

  // a little "hack" to get the experiment data from getVwoVariation
  const handleExperiment = (data) => {
    experimentData = data;
  };

  const pageContent = getVwoVariation(
    client,
    entry,
    hinAnonymousId,
    handleExperiment,
    queryVariation
  );

  return { pageContent, experimentData };
};

/**
 * @callback pageContentFetcher
 * @param {string} slug The full path of the URL
 * @param {boolean} preview Is preview mode enabled?
 * @returns {Object} The page content object returned by Contentful
 */

/**
 * Fetches page container data from Contentful and parses the response for VWO
 * variation wrappers before returning the modified response. ONLY for use inside
 * getServerSideProps. Do not use in getStaticProps.
 *
 * @param {Object} ctx The Next.js context object from getServerSideProps
 * @param {string} slug The full path of the URL
 * @param {pageContentFetcher} pageContentFetcher A function to fetch the page content
 *                                                from Contentful
 * @returns {Object} An object containing the modified pageSettings/pageContent objects
 */
export const getPageContainerData = async (ctx, slug, pageContentFetcher) => {
  const { preview, query } = ctx;
  const { settingsVariation, contentVariation } = query;

  const [rawPageSettings, rawPageContent] = await Promise.all([
    getPageSettingsBySlug(slug, preview),
    pageContentFetcher(slug, preview),
  ]);

  const vwoClient = getVwoClient();
  // This is a workaround since the cookie(s) set in middleware are not available
  // on the request object on the initial request. We set the user cookie on each
  // request anyway, so this works.
  // https://github.com/vercel/next.js/discussions/38650
  const cookies = ctx.res
    .getHeader('set-cookie')
    .map(parseSetCookieString)
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  const hinAnonymousId = cookies[process.env.NEXT_PUBLIC_USER_COOKIE];

  const { pageSettings, experimentData: settingsExperimentData } = parsePageSettings(
    rawPageSettings,
    vwoClient,
    hinAnonymousId,
    settingsVariation
  );
  const { pageContent, experimentData: contentExperimentData } = parsePageContent(
    rawPageContent,
    vwoClient,
    hinAnonymousId,
    contentVariation
  );

  return {
    pageSettings,
    pageContent,
    experimentData: [settingsExperimentData, contentExperimentData],
  };
};
