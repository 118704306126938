import { layoutFragment } from 'legacy/graphql/fragments/components/layoutFragment';
import { mastheadFragment } from 'legacy/graphql/fragments/components/mastheadFragment';
import { disclosuresFragment } from 'legacy/graphql/fragments/primitives/disclosuresFragment';
import { seoMetadataFragment } from 'legacy/graphql/fragments/primitives/seoMetadataFragment';

const pageBaseFragment = `
  seoMetadata {
    ${seoMetadataFragment}
  }
  slug
  layout {
    ${layoutFragment}
  }
  masthead {
    ${mastheadFragment}
  }
  notes {
    ${disclosuresFragment}
  }
  disclosures {
    ${disclosuresFragment}
  }
`;

export const pageBySlug = (slug, sectionFields, preview = false) => `
  query {
    pageCollection(where: {slug: "${slug}"}, preview: ${preview}, limit: 1) {
      items {
        ${pageBaseFragment}
        sectionsCollection(limit: 10) {
          items {
            __typename
            sys {
              id
            }
            ${sectionFields}
          }
        }
      }
    }
  }
`;
