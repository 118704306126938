/* istanbul ignore file */
/* eslint-disable no-console */
import vwoSdk from 'vwo-node-sdk';

import rollbar from 'utils/rollbar';

export const serverLogger = {
  logger: {
    log: (level, message) => {
      switch (level) {
        case vwoSdk.logging.LogLevelEnum.DEBUG:
          console.debug(message);
          break;
        case vwoSdk.logging.LogLevelEnum.INFO:
          console.info(message);
          break;
        case vwoSdk.logging.LogLevelEnum.ERROR:
          rollbar.error(message);
          break;
        case vwoSdk.logging.LogLevelEnum.WARN:
          console.warn(message);
          break;
        default:
          console.log(message);
          break;
      }
    },
  },
  level: vwoSdk.logging.LogLevelEnum.ERROR,
};
