import { pageSettingsType } from 'types/pages/pageContainer.types';
import { PayrollsHomePage, payrollsHomePageType } from 'humanity/pages/payrollsHomePage';
import { PageContainer } from 'humanity/pages/pageContainer';
import { getPayrollsHomePageSSG } from 'humanity/pages/payrollsHomePage/payrollsHomePage.query';

// Slug needs to match the directory/route this page is in
export const PAYROLLS_SLUG = 'payrolls-new';

const Page = ({ pageSettings, pageContent }) => (
  <PageContainer
    layout={pageSettings.layout}
    notes={pageContent.notesCollection?.items}
    disclosures={pageContent.disclosuresCollection?.items}
    seoMetadata={pageSettings.seoMetadata}
  >
    <PayrollsHomePage sections={pageContent.sectionsCollection?.items} />
  </PageContainer>
);

Page.propTypes = {
  pageSettings: pageSettingsType.isRequired,
  pageContent: payrollsHomePageType.isRequired,
};

export const getStaticProps = getPayrollsHomePageSSG(PAYROLLS_SLUG);

export default Page;
