/* eslint-disable import/prefer-default-export */
/**
 * @type {string}
 */
export const statisticFragment = `
  ... on Statistic {
    sys {
      id
    }
    heading
    headingColor
    description {
      json
    }
  }
`;
