import { basicMastheadFragment } from 'humanity/sections/basicMasthead/basicMasthead.fragment';
import { compactBannerFragment } from 'humanity/sections/compactBanner/compactBanner.fragment';
import { ctaBannerFragment } from 'humanity/sections/ctaBanner/ctaBanner.fragment';
import { ctaBoxFragment } from 'humanity/sections/ctaBox';
import { embeddedMediaFragment } from 'humanity/sections/embeddedMedia/embeddedMedia.fragment';
import { faqsFragment } from 'humanity/sections/faqs/faqs.fragment';
import { featuredTestimonialFragment } from 'humanity/sections/featuredTestimonial/featuredTestimonial.fragment';
import { featuredValuePropFragment } from 'humanity/sections/featuredValueProp/featuredValueProp.fragment';
import { fullWidthMediaFragment } from 'humanity/sections/fullWidthMedia/fullWidthMedia.fragment';
import { imageTextBlockSectionFragment } from 'humanity/sections/imageTextBlockSection';
import { largePricingCardFragment } from 'humanity/sections/largePricingCard/largePricingCard.fragment';
import { numberedStepListFragment } from 'humanity/sections/numberedStepList/numberedStepList.fragment';
import { payrollIntegrationsFragment } from 'humanity/sections/payrollIntegrations/payrollIntegrations.fragment';
import { pricingCardsFragment } from 'humanity/sections/pricingCards/pricingCards.fragment';
import { profileCardsSectionFragment } from 'humanity/sections/profileCardsSection';
import { simpleMastheadFragment } from 'humanity/sections/simpleMasthead';
import { simpleRichTextSectionFragment } from 'humanity/sections/simpleRichTextSection/simpleRichTextSection.fragment';
import { testimonialsGridFragment } from 'humanity/sections/testimonialsGrid/testimonialsGrid.fragment';
import { valuePropListFragment } from 'humanity/sections/valuePropList/valuePropList.fragment';
import { valuePropTabsFragment } from 'humanity/sections/valuePropTabs/valuePropTabs.fragment';

export const sectionTypeMap = {
  BasicMasthead: basicMastheadFragment,
  CompactBanner: compactBannerFragment,
  FeaturedValueProp: featuredValuePropFragment,
  SimpleMasthead: simpleMastheadFragment,
  LargePricingCard: largePricingCardFragment,
  NumberedStepList: numberedStepListFragment,
  ValuePropList: valuePropListFragment,
  CtaBanner: ctaBannerFragment,
  FeaturedTestimonial: featuredTestimonialFragment,
  TestimonialsGrid: testimonialsGridFragment,
  PayrollIntegrations: payrollIntegrationsFragment,
  PricingCards: pricingCardsFragment,
  FullWidthMedia: fullWidthMediaFragment,
  EmbeddedMedia: embeddedMediaFragment,
  FaqSection: faqsFragment,
  SimpleRichTextSection: simpleRichTextSectionFragment,
  ValuePropTabs: valuePropTabsFragment,
  SectionProfileCards: profileCardsSectionFragment,
  SectionImageTextBlocks: imageTextBlockSectionFragment,
  SectionCtaBox: ctaBoxFragment,
};

/**
 *
 * @param {[{ __typename: string }]} sections Deduped page sections array with typename
 */
export const mapSectionTypesToFragments = (sections) => {
  const fragments = sections.reduce(
    (prev, curr) => `${prev}${sectionTypeMap[curr.__typename]}`,
    ''
  );

  return fragments;
};
