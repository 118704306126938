/* istanbul ignore file */
import NodeCache from 'node-cache';
import vwoSdk from 'vwo-node-sdk';
import isEqual from 'lodash/isEqual';

import rollbar from 'utils/rollbar';
import buildTimeSettingsFile from 'utils/vwoSettingsFile.json';
import { serverLogger } from 'utils/vwo/serverLogger';

const vwoCache = new NodeCache({
  stdTTL: 3600,
});

const SETTINGS_KEY = 'vwo_settings_file';

const revalidateSettingsFile = async (currentFile) => {
  try {
    const settingsFile = await vwoSdk.getSettingsFile(
      process.env.NEXT_PUBLIC_VWO_ACCOUNT_ID,
      process.env.NEXT_PUBLIC_VWO_SDK_KEY
    );
    if (settingsFile && !isEqual(currentFile, settingsFile)) {
      vwoCache.set(SETTINGS_KEY, JSON.stringify(settingsFile));
    }
  } catch (err) {
    rollbar.warn('Error revalidating VWO settings file - server', err);
  }
};

const getSettingsFile = () => {
  let settingsFile;

  const cachedFile = vwoCache.get(SETTINGS_KEY);

  if (cachedFile) {
    settingsFile = JSON.parse(cachedFile);
  } else {
    settingsFile = buildTimeSettingsFile;
    vwoCache.set(SETTINGS_KEY, JSON.stringify(settingsFile));
  }
  revalidateSettingsFile(settingsFile);

  return settingsFile;
};

export const getVwoClient = () => {
  try {
    const settingsFile = getSettingsFile();
    const client = vwoSdk.launch({
      settingsFile,
      logging: serverLogger,
    });
    return client;
  } catch (err) {
    rollbar.error('Error launching VWO client', err);
    return null;
  }
};
