import { formFragment } from 'humanity/components/contentfulForm/form.fragment';

/**
 * @type {String}
 */
export const guideMastheadFragment = `
  ... on GuideMasthead {
    sys {
      id
    }
    eyebrow
    heading
    paragraph {
      json
    }
    image {
      url
      width
      height
    }
    form {
      ${formFragment()}
    }
    formSuccessCopy
    downloadHeading
    downloadParagraph {
      json
    }
    downloadCtaLabel
  }
`;
