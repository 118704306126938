import { articleBodyTextFragment } from 'legacy/components/article/articleBodyText';
import { companyNewsArticlePreviewFragment } from 'legacy/graphql/fragments/components/companyNewsArticlePreviewFragment';
import { layoutFragment } from 'legacy/graphql/fragments/components/layoutFragment';
import { authorFragment } from 'legacy/graphql/fragments/primitives/authorFragment';
import { disclosuresFragment } from 'legacy/graphql/fragments/primitives/disclosuresFragment';
import { keyTakeawaysFragment } from 'legacy/graphql/fragments/primitives/keyTakeawaysFragment';
import { seoMetadataFragment } from 'legacy/graphql/fragments/primitives/seoMetadataFragment';
import { socialSharingLinksFragment } from 'legacy/graphql/fragments/primitives/socialSharingLinksFragment';

/**
 *
 * @param {number} limit Number of articles per page
 * @param {number} skip The desired offset
 * @param {boolean} [preview=false] Whether we are in "preview" mode
 * @returns {string} The GraphQL query fragment
 */
export const companyNewsArticlePageFragment = (limit, skip, preview = false) => `
  blogArticleCollection(limit: ${limit}, skip: ${skip}, order: publishDate_DESC, preview: ${preview}) {
    limit
    total
    skip
    items {
      sys {
        id
      }
      ${companyNewsArticlePreviewFragment}
    }
  }
`;

export const companyNewsArticleBySlug = (slug, preview = false) => `
  query {
    blogArticleCollection(
      where: { slug: "${slug}" },
      preview: ${preview},
      limit: 1
    ) {
      items {
        sys {
          id
        }
        publishDate
        lastReviewedDate
        slug
        showTableOfContents
        seoMetadata {
          ${seoMetadataFragment}
        }
        layout {
          ${layoutFragment}
        }
        title
        readingLength
        author {
          ${authorFragment}
        }
        image {
          url
        }
        socialSharingOptions {
          ${socialSharingLinksFragment}
        }
        keyTakeaways {
          ${keyTakeawaysFragment}
        }
        bodyText {
          ${articleBodyTextFragment}
        }
        notes {
          ${disclosuresFragment}
        }
        disclosures {
          ${disclosuresFragment}
        }
      }
    }
  }
`;

/**
 *
 * @param {number} limit Number of articles per page
 * @param {number} skip The desired offset
 * @param {boolean} [preview=false] Whether we are in "preview" mode
 * @returns {string} The GraphQL query
 */
export const companyNewsArticlesByPage = (limit, skip, preview = false) => `
  query {
    ${companyNewsArticlePageFragment(limit, skip, preview)}
  }
`;

export const allCompanyNewsArticleSlugs = `
  query {
    blogArticleCollection {
      items {
        slug
      }
    }
  }
`;
