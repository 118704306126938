// eslint-disable-next-line import/prefer-default-export
export const inputFragment = `
  ... on Input {
    sys {
      id
    }
    type
    label
    required
    fieldName
    autocompleteValue
    placeholder
    isHalfWidth
    optionsCollection(limit: 10) {
      items {
        sys {
          id
        }
        label
        value
      }
    }
    validationRegex
    validationMessage
  }
`;
