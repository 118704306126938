export const numberedStepFragment = `
  ... on NumberedStep {
    sys {
      id
    }
    heading
    content {
      json
    }
  }
`;
