import { faqFragment } from 'humanity/components/faq/faq.fragment';

/**
 * @type {String}
 */
export const faqsFragment = `
  ... on FaqSection {
    sys {
      id
    }
    backgroundColor
    topPadding
    bottomPadding
    heading
    faqsCollection(limit: 20) {
      items {
        ${faqFragment}
      }
    }
    additionalCopy {
      json
    }
  }
`;
