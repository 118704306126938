import { ctaFragment } from 'humanity/components/cta/cta.fragment';

/**
 * @type {String}
 */
export const ctaBlockFragment = `
  ... on CtaBlock {
    sys {
      id
    }
    backgroundColor
    heading
    paragraph
    cta {
      ${ctaFragment}
    }
  }
`;
