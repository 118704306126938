/* eslint-disable import/prefer-default-export */
import { customerStoryPreviewFragment } from 'legacy/graphql/fragments/components/customerStoryPreviewFragment';
import { lcArticlePreviewFragment } from 'legacy/graphql/fragments/components/lcArticlePreviewFragment';
import { lcCalculatorPreviewFragment } from 'legacy/graphql/fragments/components/lcCalculatorPreviewFragment';
import { lcGuidePreviewFragment } from 'legacy/graphql/fragments/components/lcGuidePreviewFragment';

/**
 * @type {string}
 */
export const resourceListFragment = `
  ... on ResourceList {
    sys {
      id
    }
    type
    heading
    resourcesCollection(limit: 20) {
      items {
        __typename
        ${customerStoryPreviewFragment}
        ${lcArticlePreviewFragment}
        ${lcCalculatorPreviewFragment}
        ${lcGuidePreviewFragment}
      }
    }
  }
`;
