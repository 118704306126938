import { articleRichTextFragment } from 'legacy/graphql/fragments/primitives/articleRichTextFragment';

/**
 * @deprecated
 */
export const textBlockSimpleFragment = `
  ... on TextBlock {
    sys {
      id
    }
    heading
    headingPosition
    content {
      json
    }
  }
`;

/**
 * @deprecated
 */
export const textBlockWithEmbedsFragment = `
  ... on TextBlock {
    sys {
      id
    }
    heading
    headingPosition
    content {
      ${articleRichTextFragment}
    }
  }
`;
