import { imageFragment } from 'legacy/graphql/fragments/components/imageFragment';

export const testimonialCardFragment = `
  ... on Testimonial {
    sys {
      id
    }
    authorImage {
      ${imageFragment}
    }
    testimonialQuote
    quoteAuthor
    authorRole
  }
`;
