import { storefrontPageFragment } from 'humanity/pages/storefrontPage/storefrontPage.fragment';
import { fetchGraphQL, getPageSettingsBySlug } from 'lib/contentful';
import { getPageContainerData } from 'lib/contentful/getPageContainerData';
import { getPageSectionFragments } from 'lib/contentful/getPageSectionFragments';
import { PAYROLLS_SLUG } from 'pages/payrolls-new';
import rollbar from 'utils/rollbar';

export const storefrontPageBySlug = (slug, sectionFragments, preview = false) => `
  fragment storefrontPage on StorefrontPage {
    ${storefrontPageFragment(sectionFragments)}
  }

  fragment variationWrapper on VariationWrapper {
    meta
    campaignId
    campaignTitle
    campaignKey
    variationsCollection(limit: 4) {
      items {
        __typename
        sys {
          id
        }
        ...storefrontPage
      }
    }
  }

  query {
    pageContainerCollection(
      where: { slug: "${slug}" },
      limit: 1,
      preview: ${preview}
    ) {
      items {
        sys {
          id
        }
        page {
          __typename
          ...storefrontPage
          ...variationWrapper
        }
      }
    }
  }
`;

export const storefrontPageSectionTypesBySlug = (slug, preview = false) => `
  fragment sectionTypes on StorefrontPage {
    sectionsCollection(limit: 15) {
      items {
        __typename
      }
    }
  }

  fragment variationWrapper on VariationWrapper {
    variationsCollection(limit: 4) {
      items {
        __typename
        ...sectionTypes
      }
    }
  }

  query {
    pageContainerCollection(where: { slug: "${slug}" }, limit: 1, preview: ${preview}) {
      items {
        sys {
          id
        }
        slug
        page {
          __typename
          ...sectionTypes
          ...variationWrapper
        }
      }
    }
  }
`;

export const storefrontPageContent = (sectionFragments) => `
  ... on StorefrontPage {
    sys {
      id
    }
    sectionsCollection(limit: 15) {
      items {
        __typename
        ${sectionFragments}
      }
    }
    notesCollection(limit: 10) {
      items {
        sys {
          id
        }
        content {
          json
        }
      }
    }
    disclosuresCollection(limit: 10) {
      items {
        sys {
          id
        }
        content {
          json
        }
      }
    }
  }
`;

/**
 *
 * @param {string} slug The Page Container slug
 * @param {object} page The page object returned in getPageSettingsBySlug
 */
export const getStorefrontPageContentBySlug = async (slug, page, preview = false) => {
  const sectionFragments = getPageSectionFragments(page);
  const query = storefrontPageBySlug(slug, sectionFragments, preview);

  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.pageContainerCollection?.items?.[0]?.page;
};

export const getStorefrontPageSectionTypesBySlug = async (slug, preview = false) => {
  const query = storefrontPageSectionTypesBySlug(slug, preview);

  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.pageContainerCollection?.items?.[0]?.page;
};

export const getStorefrontPageBySlug = async (slug, preview = false) => {
  const query = storefrontPageBySlug(slug, preview);

  const entry = await fetchGraphQL(query, preview);

  return entry?.data?.pageContainerCollection?.items?.[0]?.page;
};

export const getStorefrontPageSSG = (basePath) => async (ctx) => {
  const slug = ctx.params?.slug ? `${basePath}${ctx.params.slug}` : basePath;

  try {
    const [pageSettings, pageWithSectionTypes] = await Promise.all([
      getPageSettingsBySlug(slug, ctx.preview),
      getStorefrontPageSectionTypesBySlug(slug, ctx.preview),
    ]);

    if (!pageSettings) return { notFound: true };

    const pageContent = await getStorefrontPageContentBySlug(
      slug,
      pageWithSectionTypes,
      ctx.preview
    );

    return { props: { pageSettings, pageContent }, revalidate: 900 };
  } catch (err) {
    rollbar.error(`Error fetching page with slug ${slug}`, err, err.response?.data);
    console.error(`Error fetching page with slug ${slug}`, err, err.response?.data);
    throw new Error(`Error fetching page with slug ${slug}`, { cause: err });
  }
};

export const getStorefrontPageSSR = (basePath) => async (ctx) => {
  const slug = ctx.params?.slug ? `${basePath}${ctx.params.slug}` : basePath;
  try {
    const { pageSettings, pageContent, experimentData } = await getPageContainerData(
      ctx,
      slug,
      getStorefrontPageBySlug
    );

    return { props: { pageSettings, pageContent, experimentData } };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Error fetching ${slug} page`, err, err.response?.data);
    rollbar.error(`Error fetching ${slug} page`, err, err.response?.data);
    throw new Error(`Error fetching ${slug} page`, { cause: err });
  }
};

export const getPageSlugsQuery = (slug) => `
  query {
    pageContainerCollection(
      limit: ${process.env.NEXT_PUBLIC_PAGE_SIZE},
      where: { slug_contains: "${slug}", slug_not: "${slug}" }
    ) {
      items {
        slug
      }
    }
  }
`;

export const allIndustriesPageSlugs = `
  query {
    pageContainerCollection(
      limit: ${process.env.NEXT_PUBLIC_PAGE_SIZE},
      where: { slug_contains: "industries", slug_not: "industries" }
    ) {
      items {
        slug
      }
    }
  }
`;

export const allLandingPageSlugs = `
  query {
    pageContainerCollection(
      limit: ${process.env.NEXT_PUBLIC_PAGE_SIZE},
      where: { slug_contains: "lp", slug_not: "lp" }
    ) {
      items {
        slug
      }
    }
  }
`;

export const allSolutionsPageSlugs = `
  query {
    pageContainerCollection(
      limit: ${process.env.NEXT_PUBLIC_PAGE_SIZE},
      where: { slug_contains: "solutions", slug_not: "solutions" }
    ) {
      items {
        slug
      }
    }
  }
`;

export const allPartnersPageSlugs = `
  query {
    pageContainerCollection(
      limit: ${process.env.NEXT_PUBLIC_PAGE_SIZE},
      where: { slug_contains: "partners", slug_not: "partners" }
    ) {
      items {
        slug
      }
    }
  }
`;

export const allPayrollsPageSlugs = `
  query {
    pageContainerCollection(
      limit: ${process.env.NEXT_PUBLIC_PAGE_SIZE},
      where: { slug_contains: "payrolls", slug_not_in: ["payrolls", "supported-payrolls", "${PAYROLLS_SLUG}"] }
    ) {
      items {
        slug
      }
    }
  }
`;

export const allStateComparisonsPageSlugs = `
  query {
    pageContainerCollection(
      limit: ${process.env.NEXT_PUBLIC_PAGE_SIZE},
      where: { slug_contains: "state-comparisons", slug_not: "state-comparisons" }
    ) {
      items {
        slug
      }
    }
  }
`;
