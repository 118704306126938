/* eslint-disable import/prefer-default-export */
import { imageFragment } from 'legacy/graphql/fragments/components/imageFragment';

/**
 * @type {string}
 */
export const authorFragment = `
  ... on Author {
    name
    description
    image {
      ${imageFragment}
    }
  }
`;
