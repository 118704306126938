import PropTypes from 'prop-types';

import { contentfulMediaType } from 'types/common/contentfulMedia.types';

export const lcResourcePreviewType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  subcategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  resourceType: PropTypes.oneOf(['Articles', 'Calculators', 'Guides', 'Webinars'])
    .isRequired,
  resource: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    image: contentfulMediaType.isRequired,
  }),
});

export const lcResourcePreviewProps = {
  resource: lcResourcePreviewType.isRequired,
};
