import { pricingCardFragment } from 'humanity/components/pricingCard/pricingCard.fragment';

/**
 * @type {String}
 */
// not using pricingPlanFragment here since we only need `title`
export const planFeatureFragment = `
  ... on PlanFeature {
    sys {
      id
    }
    content {
      json
    }
    plansCollection(limit: 3) {
      items {
        sys {
          id
        }
        title
      }
    }
    badge
  }
  ... on PlanFeatureHeader {
    sys {
      id
    }
    header
  }
`;

/**
 * @type {String}
 */
export const pricingCardsFragment = `
  ... on PricingCards {
    sys {
      id
    }
    topPadding
    bottomPadding
    heading
    banner {
      json
    }
    subheading
    savingsToggleText
    savingsToggleDefaultValue
    pricingCardsCollection(limit: 3) {
      items {
        ${pricingCardFragment}
      }
    }
    disclosures {
      json
    }
    featuresHeading
    featuresCollection(limit: 20) {
      items {
        ${planFeatureFragment}
      }
    }
    featuresDisclosures {
      json
    }
  }
`;
