/* eslint-disable import/prefer-default-export */
/**
 * @type {string}
 */
export const paragraphWithImageFragment = `
  ... on ParagraphWithImage {
    sys {
      id
    }
    image {
      url
    }
    imageLocation
    content {
      json
    }
  }
`;
