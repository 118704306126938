/* eslint-disable import/prefer-default-export */
import { iconFragment } from 'legacy/graphql/fragments/components/iconFragment';
import { linkFragment } from 'legacy/graphql/fragments/components/linkFragment';
import { listFragment } from 'legacy/graphql/fragments/primitives/listFragment';
import { textBlockSimpleFragment } from 'legacy/graphql/fragments/sections/textBlockFragment';

export const footerNavFragment = `
  ... on FooterNavigation {
    sys {
      id
    }
    contactSectionHeading
    contactSectionBlocksCollection(limit: 3) {
      items {
        ${textBlockSimpleFragment}
      }
    }
    socialMediaIconsCollection(limit: 3) {
      items {
        ${iconFragment}
      }
    }
    sectionsCollection(limit: 4) {
      items {
        ${listFragment(linkFragment, 10)}
      }
    }
    copyrightInfo {
      json
    }
  }
`;
