import { dripiconFragment } from 'humanity/components/dripicon/dripicon.fragment';
import { tabbedValuePropsFragment } from 'humanity/components/tabbedValueProps/tabbedValueProps.fragment';
import { valuePropWithImageFragment } from 'humanity/components/valuePropWithImage/valuePropWithImage.fragment';

/**
 * @type {String}
 */
export const valuePropTabsFragment = `
  ... on ValuePropTabs {
    sys {
      id
    }
    backgroundColor
    topPadding
    bottomPadding
    eyebrow
    heading
    tabsCollection(limit: 10) {
      items {
        sys {
          id
        }
        title
        icon {
          ${dripiconFragment}
        }
        tabContent {
          __typename
          ${tabbedValuePropsFragment}
          ${valuePropWithImageFragment}
        }
      }
    }
    disclosures {
      json
    }
  }
`;
