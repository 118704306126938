import { listFragment } from 'legacy/graphql/fragments/primitives/listFragment';
import { imageFragment } from 'legacy/graphql/fragments/components/imageFragment';
import { ctaFragment } from 'legacy/graphql/fragments/components/ctaFragment';
import { linkFragment } from 'legacy/graphql/fragments/components/linkFragment';

/* eslint-disable import/prefer-default-export */
export const headerNavFragment = `
  ... on HeaderNavigation {
    sys {
      id
    }
    internalTitle
    mobileImage {
      ${imageFragment}
    }
    desktopImage {
      ${imageFragment}
    }
    linksCollection(limit: 8) {
      items {
        __typename
        ${linkFragment}
        ${ctaFragment}
        ${listFragment(linkFragment, 8)}
      }
    }
  }
`;
