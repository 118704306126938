import { FC } from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';

import Image from 'humanity/primitives/image';
import { CtaProps } from 'humanity/components/cta/cta.types';
import SimpleRichText from 'humanity/components/simpleRichText';
import Cta from 'humanity/components/cta';
import Section from 'humanity/primitives/section';
import Heading from 'humanity/primitives/heading';
import { SimpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import Container from 'humanity/primitives/container';
import Flex from 'humanity/primitives/flex';
import Box from 'humanity/primitives/box';
import { theme } from 'utils/theme.v2';

const StyledFlex = styled(Flex)(
  css({
    flexDirection: ['column-reverse', null, 'row'],
    borderRadius: theme.radii.md,
  })
);
type ImageType = {
  altText: string;
  width: number | null;
  height: number | null;
  image: {
    url: string;
  };
};
type Props = {
  header: string;
  backgroundColor: string;
  innerBackgroundColor: string;
  disclosure?: SimpleRichTextType;
  textField?: SimpleRichTextType;
  image?: ImageType;
  cta: Omit<CtaProps, 'btnSize'>;
  topPadding: number;
  bottomPadding: number;
};

const CtaBox: FC<Props> = ({
  header,
  backgroundColor,
  innerBackgroundColor,
  disclosure,
  textField,
  image,
  cta,
  topPadding,
  bottomPadding,
}) => (
  <Section bg={backgroundColor} pt={topPadding} pb={bottomPadding}>
    <Container>
      <StyledFlex bg={innerBackgroundColor} p={6} gap={[5, null]}>
        {image && image.image?.url && (
          <Box minWidth={image?.width} minHeight={image?.height} mr={[0, null, 4, 9]}>
            <Image src={image.image.url} width={image.width} height={image.height} />
          </Box>
        )}
        <Box>
          <Heading as="h3" variant={['h6', null, 'h3']} mb={2}>
            {header}
          </Heading>
          {textField && <SimpleRichText content={textField.json} color="black" />}
          <Box py={3}>
            <Cta btnSize="lg" {...cta} />
          </Box>
          {disclosure && (
            <SimpleRichText content={disclosure.json} color="black" fontSize="10px" />
          )}
        </Box>
      </StyledFlex>
    </Container>
  </Section>
);

export default CtaBox;
