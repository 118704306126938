import { ctaFragment } from 'humanity/components/cta/cta.fragment';

export const featuredTestimonialFragment = `
  ... on FeaturedTestimonial {
    sys {
      id
    }
    backgroundColor
    topPadding
    bottomPadding
    heading
    subheading
    quote
    image {
      url
    }
    author
    authorRole
    cta {
      ${ctaFragment}
    }
    disclosures {
      json
    }
  }
`;
