/* eslint-disable import/prefer-default-export */
import { articleRichTextFragment } from 'legacy/graphql/fragments/primitives/articleRichTextFragment';

/**
 * @type {string}
 */
export const articleBodyTextFragment = `
  ... on ArticleBodyText {
    sys {
      id
    }
    internalTitle
    content {
      ${articleRichTextFragment}
    }
  }
`;
