/* eslint-disable import/prefer-default-export */
/**
 *
 * @param {string} listItemFragments String containing GraphQL fragments for the
 *                                   possible list items
 * @param {number} [limit=10]        Limit on the list items
 * @returns {string}                 GraphQL fragment representing a List type
 */
export const listFragment = (listItemFragments, limit = 10) => `
  ... on List {
    sys {
      id
    }
    title
    itemsCollection(limit: ${limit}) {
      items {
        sys {
          id
        }
        ${listItemFragments}
      }
    }
  }
`;
