import { dripiconFragment } from 'humanity/components/dripicon/dripicon.fragment';

/**
 * @type {String}
 */
export const featuredHighlightFragment = `
  ...on FeaturedHighlight {
    sys {
      id
    }
    heading
    highlightText {
      json
    }
    icon {
      ${dripiconFragment}
    }
  }
`;
