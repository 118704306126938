import { formFragment } from 'legacy/graphql/fragments/components/formFragment';

/* eslint-disable import/prefer-default-export */
export const signupVariantById = (id) => `
  query {
    variantCollection(where: { variantId: ${id} }, limit: 1) {
      items {
        title
        sectionsCollection(limit: 5) {
          items {
            sys {
              id
            }
            title
            label
            slug
            stepsCollection(limit: 10) {
              items {
                sys {
                  id
                }
                title
                subTitle
                fieldName
                isSummary
                content {
                  json
                }
                optionsCollection(limit: 15) {
                  items {
                    sys {
                      id
                    }
                    value
                    label {
                      json
                    }
                    newLabel {
                      json
                    }
                  }
                }
                form {
                  ${formFragment}
                }
                helpText {
                  title
                  content {
                    json
                  }
                }
                showSalesCallButton
              }
            }
          }
        }
      }
    }
  }
`;
