/* eslint-disable import/prefer-default-export */
import { layoutFragment } from 'legacy/graphql/fragments/components/layoutFragment';

/**
 *
 * @param {boolean} [preview=false]
 * @returns
 */
const careersPage = (preview = false) => `
  query {
    careersPageCollection(limit: 1, preview: ${preview}) {
      items {
        seoTitle
        seoDescription
        layout {
          ${layoutFragment}
        }
        heroTitle
        heroSubtitle
        ctaText
        ctaUrl
        sectionTwoTitle
        sectionTwoContent {
          json
        }
        sectionTwoImageLeftOne {
          url
        }
        sectionTwoImageLeftTwo {
          url
        }
        sectionTwoImageRight {
          url
        }
        teamSectionTitle
        teamSectionSubheading
        employeeQuotesCollection(limit: 5) {
          items {
            sys {
              id
            }
            image {
              url
            }
            employeeName
            employeeRole
            quoteCopy
          }
        }
        valuesSection {
          heading
          valuesCollection(limit: 5) {
            items {
              sys {
                id
              }
              heading
              paragraph
            }
          }
        }
        employeeBenefitsSectionTitle
        employeeBenefitsSectionSubtitle
        employeeBenefitsCollection(limit: 15) {
          items {
            sys {
              id
            }
            icon {
              url
            }
            copy {
              json
            }
          }
        }
        aboutUsSection {
          heading
          subheading
        }
        aboutUsSectionTitle
        aboutUsValuePropsCollection(limit: 5) {
          items {
            sys {
              id
            }
            title
            content {
              json
            }
            icon
          }
        }
        pressSectionTitle
        pressQuotesCollection(limit: 3) {
          items {
            sys {
              id
            }
            copy
            image {
              url
            }
          }
        }
        locationSectionTitle
        locationSectionContent {
          json
        }
        locationSectionAddress {
          json
        }
        bannerImageOne {
          title
          url
        }
        jobOpeningsSectionTitle
        bannerImageTwo {
          title
          url
        }
      }
    }
  }
`;

export default careersPage;
