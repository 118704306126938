/* eslint-disable import/prefer-default-export */

import { ctaFragment } from 'legacy/graphql/fragments/components/ctaFragment';

export const ctaSectionFragment = `
  ... on CtaSection {
    sys {
      id
    }
    size
    variant
    heading
    subheading
    cta {
      ${ctaFragment}
    }
  }
`;
