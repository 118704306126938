/* eslint-disable import/prefer-default-export */
import { valuePropWithImageFragment } from 'legacy/graphql/fragments/components/valuePropWithImageFragment';

/**
 * @type {string}
 */
export const valuePropWithImageSectionFragment = `
  ... on ValuePropWithImageSection {
    sys {
      id
    }
    backgroundColor
    heading
    valuePropsCollection(limit: 3) {
      items {
        ${valuePropWithImageFragment}
      }
    }
  }
`;
