export const payrollIntegrationCardFragment = `
  ... on PayrollIntegration {
    sys {
      id
    }
    title
    logo {
      sys {
        id
      }
      width
      height
      image {
        url
        width
        height
      }
    }
    url
  }
`;
