/* eslint-disable import/prefer-default-export */
import { imageFragment } from 'legacy/graphql/fragments/components/imageFragment';
import { tableFragment } from 'legacy/graphql/fragments/primitives/tableFragment';
import { ctaSectionFragment } from 'legacy/graphql/fragments/sections/ctaSectionFragment';
import { paragraphWithImageFragment } from 'legacy/graphql/fragments/sections/paragraphWithImageFragment';
import { featuredHighlightFragment } from 'humanity/components/featuredHighlight/featuredHighlight.fragment';
import { inlineBannerCtaFragment } from 'humanity/components/inlineBannerCta/inlineBannerCta.fragment';

/**
 * @type {string}
 */
export const articleRichTextFragment = `
  json
  links {
    assets {
      block {
        sys {
          id
        }
        title
        url
      }
    }
    entries {
      hyperlink {
        __typename
        sys {
          id
        }
      }
      inline {
        __typename
        sys {
          id
        }
      }
      block {
        __typename
        sys {
          id
        }
        ${imageFragment}
        ${paragraphWithImageFragment}
        ${ctaSectionFragment}
        ${tableFragment}
        ${featuredHighlightFragment}
        ${inlineBannerCtaFragment}
      }
    }
  }
`;
