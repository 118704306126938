import { ctaFragment } from 'legacy/graphql/fragments/components/ctaFragment';

/**
 * @type {String}
 */
export const featuredValuePropFragment = `
  ... on FeaturedValueProp {
    sys {
      id
    }
    backgroundColor
    topPadding
    bottomPadding
    image {
      url
    }
    imagePosition
    imageAlignment
    eyebrow
    heading
    paragraph {
      json
    }
    disclosures {
      json
    }
    cta {
      ${ctaFragment}
    }
  }
`;

// export const featuredValuePropQuery = gql`
//   query getFeaturedValueProp($id: String!) {
//     featuredValueProp(id: $id) {
//       ...featuredValuePropFragment
//     }
//   }
//   ${featuredValuePropFragment}
// `;
