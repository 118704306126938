import { footerNavFragment } from 'legacy/graphql/fragments/components/footerNavFragment';
import { headerNavFragment } from 'legacy/graphql/fragments/components/headerNavFragment';
import { variationWrapperFragment } from 'legacy/graphql/fragments/primitives/variationWrapperFragment';
import { headerNavFragment as headerNavV2Fragment } from 'humanity/sections/headerNav/headerNav.fragment';

/* eslint-disable import/prefer-default-export */
export const layoutFragment = `
  ... on Layout {
    sys {
      id
    }
    headerNavigation {
      __typename
      ${headerNavFragment}
      ${headerNavV2Fragment}
      ${variationWrapperFragment(headerNavV2Fragment)}
    }
    footerNavigation {
      ${footerNavFragment}
    }
  }
`;
