import { ctaFragment } from 'humanity/components/cta/cta.fragment';

export const valuePropWithImageFragment = `
  ... on ValuePropWithImage {
    sys {
      id
    }
    image {
      url
      width
      height
    }
    heading
    content {
      json
    }
    cta {
      ${ctaFragment}
    }
  }
`;
