import { variationWrapperFragment } from 'legacy/graphql/fragments/primitives/variationWrapperFragment';
import { seoMetadataFragment } from 'legacy/graphql/fragments/primitives/seoMetadataFragment';
import { keyTakeawaysFragment } from 'legacy/graphql/fragments/primitives/keyTakeawaysFragment';
import { layoutFragment } from 'legacy/graphql/fragments/components/layoutFragment';
import { articleBodyTextFragment } from 'legacy/components/article/articleBodyText';
import { disclosuresFragment } from 'legacy/graphql/fragments/primitives/disclosuresFragment';
import { leadCaptureFragment } from 'legacy/graphql/fragments/sections/leadCaptureFragment';
import { ctaBlockFragment } from 'humanity/components/ctaBlock/ctaBlock.fragment';
import { lcResourcePreviewFragment } from 'humanity/components/lcResourcePreview';

export const learningCenterArticleBySlug = (slug, preview = false) => `
  query {
    learningCenterArticleCollection(
      where: { slug: "${slug}" },
      preview: ${preview},
      limit: 1
    ) {
      items {
        sys {
          id
        }
        slug
        publishDate
        lastReviewedDate
        showTableOfContents
        sidebarCta {
          ${ctaBlockFragment}
        }
        seoMetadata {
          ${seoMetadataFragment}
        }
        layout {
          ${layoutFragment}
        }
        title
        readingLength
        author {
          sys {
            id
          }
          name
          slug
          description
          image {
            image {
              url
            }
          }
        }
        keyTakeaways {
          ${keyTakeawaysFragment}
        }
        socialSharingOptions {
          sys {
            id
          }
          emailBody
          emailSubject
          twitterText
          twitterHashtags
        }
        bodyText {
          ${articleBodyTextFragment}
          ${variationWrapperFragment(articleBodyTextFragment)}
        }
        categoriesCollection(limit: 1) {
          items {
            sys {
              id
            }
            title
            slug
          }
        }
        subcategoriesCollection(limit: 1) {
          items {
            sys {
              id
            }
            title
            slug
          }
        }
        disclosures {
          ${disclosuresFragment}
        }
        notes {
          ${disclosuresFragment}
        }
        leadCapture {
          ${leadCaptureFragment}
        }
        relatedResources {
          resourcesCollection {
            items {
              ${lcResourcePreviewFragment}
            }
          }
        }
      }
    }
  }
`;

export const allLearningCenterArticleSlugs = `
  query {
    learningCenterArticleCollection(limit: 40) {
      items {
        slug
      }
    }
  }
`;
